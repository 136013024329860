@font-face {
    font-family: 'Circe';
    src: url('./Circe-Light.woff2') format('woff2'),
    url('./Circe-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('./Circe-Regular.woff2') format('woff2'),
    url('./Circe-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('./Circe-Bold.woff2') format('woff2'),
    url('./Circe-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('./Circe-ExtraBold.woff2') format('woff2'),
    url('./Circe-ExtraBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
