 $baseFont: 'Circe';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $baseFont;
  -webkit-font-smoothing: antialiased;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}